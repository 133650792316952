import Modal from "react-modal";
import React, { useContext } from "react";
import firstLogo from "../../images/Group18.png";
import secondLogo from "../../images/VectorSmartObject.png";
import { ScoreContext } from "../../Context/ScoreContext";
import coin from "../../images/coin.svg";
import "../WinModal/WinModal.css";
import { Link, useNavigate } from "react-router-dom";
import { LevelContext } from "../../Context/LevelContext";
import axios from "axios";
import QRCode from "../../images/QR_Code.png";
// import backgroundVideo from "../../videos/golden-particles.mov";

export const WinModal = ({ show, onClose, restartGame, currentLevel }) => {
  const { score, totalScore, setScore } = useContext(ScoreContext);
  const { completeLevel } = useContext(LevelContext);
  const navigate = useNavigate();

  const postVisitorScore = () => {
    axios
      .post("https://betalocgame.orevan.net/api/visitors", {
        id: localStorage.getItem("userId"),
        name: localStorage.getItem("userName"),
        score: score,
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("userId", response.data.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReturn = () => {
    postVisitorScore();
    localStorage.setItem("score", 0);
    setScore(0);
  };

  const handleNextLevel = () => {
    completeLevel(currentLevel + 1);
    localStorage.setItem("score", totalScore);
    navigate(`/Level${currentLevel + 1}Component`); // Adjust the route according to your setup
    postVisitorScore();
    onClose();
  };

  return (
    <>
      <Modal className="winModalBox" isOpen={show} onRequestClose={onClose}>
        <div className="winModalCotainer">
          {/* <video className='backgroundVideo' autoPlay loop muted>
            <source src={backgroundVideo} />
          </video> */}
          <div className="winContent">
            <h1 className="mb-3">Congratulations! </h1>
            <h3 className="placed">You placed all circles correctly.</h3>
            <div className="contentScore">
              <div className="mr-5">
                <img className="w-100 " src={firstLogo} alt="" />
              </div>
              {currentLevel === 3 && (
                <div className="totalScore mr-5 ">
                  <h4>Total Score </h4>{" "}
                  {/* Display total score after third level */}
                  <div className="scoreBox">
                    <span className="scoreNum">{score}</span>
                    <img className="coinImage3" src={coin} alt="" />
                  </div>
                </div>
              )}
              <div>
                <img className="w-100 mb-2" src={secondLogo} alt="" />
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-4 flex-wrap ">
              <button onClick={restartGame} className=" playButton">
                Play Again
              </button>
              {currentLevel === 2 && (
                <div>
                  <Link to="/ChoosePage">
                    <button onClick={handleReturn} className="playButton ">
                      Return Home{" "}
                    </button>
                  </Link>
                </div>
              )}
              {currentLevel < 2 && (
                <div>
                  <Link to="/ChoosePage">
                    <button className="playButton">Return Home </button>
                  </Link>
                  <button onClick={handleNextLevel} className="nextButton">
                    To Next Level
                  </button>
                </div>
              )}
            </div>
            {/* <div className="d-flex mt-md-4 mt-0  justify-content-center  ">
             

             <div className=" bg-white w-25 rounded-md">
               <img src={QRCode} alt="" />
             </div>
           
           </div> */}
            <div className="row justify-content-center mt-md-4 mt-0 ">
              <div className="col-6 col-md-4">
               
                <img className="img-fluid" src={QRCode} alt="" />
                
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
