import React, { useEffect, useRef, useState } from "react";
// import homeImage from "../../images/Artboard.png";
import firstLogo from "../../images/Group18.png";
import secondLogo from "../../images/VectorSmartObject.png";
import coinImage from "../../images/CoinAnimation2.gif";
// import backgroundAudio from "../../audios/presentation.wav";
import { ToastContainer, toast } from "react-toastify";

import "../Home/Home.css";

import { useNavigate } from "react-router-dom";

export function Home() {
  const [redirectToPage, setRedirectToPage] = useState(false);
  //   const [isLogo, setIsLogo] = useState(true);

  const navigate = useNavigate();

  function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
  }

  useEffect(() => {
    if (isMobile()) {
      toast.info("For better experience, Please use a laptop or tablet screen.", {
        position: "top-right",
        autoClose: 6000,
      });
    } else {
      console.log("User is not on a mobile device");
    }

    const timer = setTimeout(() => {
      setRedirectToPage(true);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (redirectToPage) {
      navigate("/Page");
    }
  }, [redirectToPage, navigate]);

  return (
    <>
      <ToastContainer />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12 cont">
            <div className="homeContainer">
              <div className="homeContent">
                <h1>Where is Betaloc ZOK?</h1>
              </div>
              <div className="titleButtom">
                <h3>
                  This game uses interactive learning to enhance patient
                  outcomes.
                </h3>
              </div>
              {/* <div className="sideHome">
                <img className="w-100 mx-2" src={firstLogo} alt="" />
                <img className="w-100" src={secondLogo} alt="" />
              </div> */}
              <div className="coinHome">
                <img className="w-50" src={coinImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
