import React from "react";
import "../Footer/Footer.css";
import AstraLogo from "../../images/AstrazencaLogo.png";
import zokLogo from "../../images/BetalocZOK.png"

export default function Footer() {
  return (
    <div className="footerStyle">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className=" md:text-left footerContent">
              <p className="py-2 paragraphFooter">
                AstraZeneca Near East; Sweifieh, <br />
                BLDG 19 Abdelraheem Al-Haj Mohammad street, <br />
                P.O. Box: 17788, Zip Code 11195 Amman-Jordan; Tel: +96265827580 -
                +9611385534
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="emailInfo footerContent">
              <p className="py-2 paragraphFooter">
                For Medical: medinfo-ne@astrazeneca.com <br />
                For Adverse Events: Patientsafety.ne@astrazeneca.com
              </p>
            </div>
          </div>

          <hr className="footer-divider" />

          <div className="row items-center">
            <div className="col-xl-4 col-lg-6 col-12">
              <div className="row justify-center">
                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-3 col-sm-4 col-4">
                  <img
                    src={AstraLogo}
                    alt="logo"
                  />
                </div>
                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-3 col-sm-4 col-4">
                  <img
                    src={zokLogo}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <div className="logoContent py-3">
                <a
                  className="footerLinks"
                  href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Legal Notices
                </a>
                <a
                  className="footerLinks"
                  href="https://www.azprivacy.astrazeneca.com/middle-east-and-africa/egypt/en/privacy-notices.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Notices
                </a>
                <a
                  className="footerLinks"
                  href="https://storage.googleapis.com/livesod/Astrazeneca/BET-Label.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prescribing Information
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-12">
              <div className="mt-xl-0 mt-lg-4 mt-0">
                <p className="poweredBox">
                  Powered by Astrazenca <br /> MC: SEL-87-WEB-0824-0826-NE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
