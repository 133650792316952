import React, { useContext, useEffect, useState } from "react";
import firstLogo from "../../images/Group18.png";
import secondLogo from "../../images/VectorSmartObject.png";
import "../ChoosePage/ChoosePage.css";
import { Link } from "react-router-dom";
import UserNameContext from "../../Context/UserNameContext.js";
// import { ScoreContext } from '../../Context/ScoreContext';
import { LevelContext } from "../../Context/LevelContext.js";
import can from "../../images/theme1.jpg";
import can1 from "../../images/theme2.jpg";
import can2 from "../../images/Theme3.jpg";
// import coinImage from "../../images/coin.svg";
import QRcode from "../../images/QR_Code.png";

export function ChoosePage() {
  const { userName, setUserName } = useContext(UserNameContext);
  const { completedLevels, setCompletedLevels } = useContext(LevelContext);

  const isLevelAvailable = (level) => completedLevels.includes(level);

  useEffect(() => {
    const storedName = localStorage.getItem("userName");
    if (storedName) setUserName(storedName);

    if (localStorage.getItem("level") !== null) {
      const level = parseInt(localStorage.getItem("level"));
      setCompletedLevels((prevLevels) => [...prevLevels, level]);
      isLevelAvailable(level);
    }
  }, [setUserName]);

  useEffect(() => {
    localStorage.removeItem("answeredQuestions");
  }, []);

  return (
    <>
      <div className="box h-screen overflow-auto">
        <div className="container">
          <div className="parent-container1">
            <div className="text-white text-center">
              <h2>Hello Dr. {userName}</h2>
            </div>
            <h3 className="text-white mt-1 text-center">
              Choose a Puzzle and find Betaloc boxes!
            </h3>
            <div className="row justify-content-center align-items-center mt-4">
              <div className="col-lg-5 col-md-6 col-sm-9 col-8 mb-3">
                <div className="blocksGame rounded">
                  {isLevelAvailable(1) ? (
                    <Link to="/Level3Component">
                      <img className="sm:p-2 p-0" src={can2} alt="theme" />
                    </Link>
                  ) : (
                    <img
                      className="sm:p-2 p-0"
                      src={can2}
                      alt="theme"
                      style={{ opacity: 0.5 }}
                    />
                  )}
                </div>
                <h4 className="titleLevel">Theme 1</h4>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-9 col-8 mb-3">
                <div className="blocksGame rounded">
                  {isLevelAvailable(2) ? (
                    <Link to="/Level2Component">
                      <img className="sm:p-1 p-0" src={can1} alt="theme" />
                    </Link>
                  ) : (
                    <img
                      className="sm:p-1 p-0"
                      src={can1}
                      alt="theme"
                      style={{ opacity: 0.5 }}
                    />
                  )}
                </div>
                <h4 className="titleLevel">Theme 2</h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-center mb-4">
                <Link to="/Page">
                  <button className="playButton m-0">Return Back</button>
                </Link>
              </div>
              <div className="col-12 mb-4">
                <div className="sideHome mx-auto m-0">
                  <img className="w-100 mx-2" src={firstLogo} alt="" />
                  <img className="w-100" src={secondLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
